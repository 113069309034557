<template>
  <div class="vw-100 vh-100 post-register data-entry">
    <!-- If user in pre-pending status -->
    <!-- Nav is hidden in this state -->
    <div class="container-fluid h-100">
      <div class="row h-100">
        <div class="col-md-3 justify-content-center text-center left-col">
          <img
            src="@/assets/images/logo-onwhite.svg"
            alt="International Federation of Automatic Control"
            class="logo"
          />
        </div>
        <div class="col d-flex align-items-center h-100 right-col">
          <div class="card border-0 shadow-none h-100 w-100">
            <div
              class="card-body"
              style="height: 100%; max-height: 100%; overflow: auto"
            >
              <!--  -->
              <div class="row">
                <div class="col-md-10 mx-auto">
                  <h2>
                    Account Registration
                  </h2>

                  <step-1 v-model="form"></step-1>

                  <h4>Fields of interest</h4>
                  <ifac-currently-selected-fields
                    v-show="selectedKws.length > 0"
                    :selected-kws="selectedKws"
                  />
                  <div class="row">
                    <div class="col">
                      <button class="btn" v-b-modal.ifac-fields-of-interest>
                        {{ fieldsOfInterestLabel() }}
                      </button>
                    </div>
                  </div>
                  <ifac-interest-selector
                    :selected-keywords="selectedKws"
                    :selected-technical-committees="selectedTcs"
                    @updateTcs="updateTcs"
                    @updateKws="updateKeywords"
                  />

                  <div class="row submit-buttons">
                    <div class="col-auto ml-auto">
                      <button
                        class="btn btn-sm btn-light mr-3"
                        @click="logout"
                      >
                        Cancel
                      </button>

                      <button
                        class="btn btn-primary"
                        :disabled="!canSubmit"
                        @click="completeRegistration"
                      >
                        <i v-if="busy" class="far fa-spinner fa-spin mr-2"></i
                        >Create Account
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Affiliates from '@/services/Api/Affiliates';
import {
  IfacInterestSelector,
  IfacCurrentlySelectedFields,
  IfacAffiliatesHelpers,
} from '@ifac/ui';
import Step1 from './register-partials/Step1.vue';

export default {
  data() {
    return {
      busy: false,
      form: {
        affiliation: '',
        countryCode: '',
        newsletterSubscribed: false,
      },
      selectedTcs: [],
      selectedKws: [],
    };
  },
  computed: {
    ...mapGetters({
      isRegistrationComplete: 'auth/isRegistrationComplete',
      user: 'auth/user',
      keycloakProfile: 'auth/keycloakProfile',
    }),
    canSubmit() {
      if (this.busy) {
        return false;
      }

      return this.form.affiliation.length > 0 && this.form.countryCode.length > 0 && this.selectedKws.length > 0;
    },
  },
  methods: {
    ...mapActions({
      setUser: 'auth/setUser',
    }),
    logout() {
      this.$store.dispatch('auth/logout');
    },
    fieldsOfInterestLabel() {
      return (this.selectedKws.length > 0) ? 'Update fields of interest' : 'Select fields of interest';
    },
    updateTcs(tcs) {
      this.selectedTcs = tcs;
    },
    updateKeywords(keywords) {
      this.selectedKws = IfacAffiliatesHelpers.sortFoiKeywords(keywords);
    },
    async completeRegistration() {
      this.busy = true;

      try {
        this.form.id = this.user.id;
        this.form.name = this.keycloakProfile.firstName;
        this.form.surname = this.keycloakProfile.lastName;
        this.form.fieldsOfInterest = this.selectedKws.map((a) => a.id);
        await Affiliates.update(this.form);
        await this.setUser();
      } catch (error) {
        this.$snack.danger({
          text: 'Could not complete registration. Please try again.',
        });
        this.busy = false;
        return;
      }

      this.busy = false;
      this.$router.push('/dashboard');
    },
  },
  created() {
    if (this.isRegistrationComplete) {
      this.$router.push({ name: 'Home' });
    }
  },
  components: {
    IfacInterestSelector,
    IfacCurrentlySelectedFields,
    Step1,
  },
};
</script>

<style lang='scss'>
@import '@/assets/styles/base/_variables.scss';
.post-register {
  .selected-keywords {
    margin-bottom: 0.625rem;
  }
  .submit-buttons {
    margin-top: 1.875rem;
  }
  .left-col {
    padding: 1rem;
    @media (min-width: 1140px) {
      padding: 3rem;
    }
    background-color: $color-blue-register;
    img {
      &.logo {
        width: 100%;
        min-width: 9.375rem;
        max-width: 18.75rem;
      }
    }
  }
  .right-col {
    background-color: $color-white;
    padding: 0rem;
    @media (min-width: 768px) {
      padding: 3rem;
    }
  }
}
</style>
