<template>
  <div class="row">
    <div class="col mx-auto">
      <div class="row input-row">
        <div class="col">
          <label for="affiliation">Affiliation</label>
          <input
            id="affiliation"
            type="text"
            v-model="value.affiliation"
            class="form-control"
            placeholder="Affiliation"
          />
          <small class="form-text text-muted">Please input your affiliation</small>
        </div>
      </div>
      <div class="row input-row" v-if="countries.length > 0">
        <div class="col">
          <label for="">Country / Region</label>

          <ifac-search-dropdown
            :options="countries"
            placeholder="Select a country / region"
            :current="value.country"
            currentType="object"
            currentValue="name"
            optionValue="code"
            optionTitle="name"
            searchAgainst="name"
            emitType="string"
            emitValue="code"
            @selected="value.countryCode = $event"
          ></ifac-search-dropdown>
        </div>
      </div>

      <h4>Newsletter</h4>
      <div class="row input-row">
        <div class="col">
          <p class="text-sm mb-3">{{ newsletterText }}</p>
          <b-button-group>
            <button
              type="button"
              class="btn"
              :class="value.newsletterSubscribed === true ? 'active' : ''"
              @click="value.newsletterSubscribed = true"
            >
              Yes please
            </button>
            <button
              type="button"
              class="btn"
              :class="value.newsletterSubscribed === false ? 'active' : ''"
              @click="value.newsletterSubscribed = false"
            >
              No thanks
            </button>
          </b-button-group>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  IfacSearchDropdown,
  AFFILIATE_REGISTRATION_FORM,
} from '@ifac/ui';
import Countries from '@/services/Api/Countries';

export default {
  components: {
    IfacSearchDropdown,
  },
  props: ['value'],
  data() {
    return {
      countries: [],
    };
  },
  computed: {
    newsletterText() {
      return AFFILIATE_REGISTRATION_FORM.newsletter;
    },
  },
  created() {
    this.fetchCountries();
  },
  watch: {
    value: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
    },
  },
  methods: {
    codeAndNameAndDesc(item) {
      return `${item.code} - ${item.name} - ${item.desc}`;
    },
    async fetchCountries() {
      const {
        data: { data },
      } = await Countries.all();
      this.countries = data;
    },
  },
};
</script>
